import React from "react";

function Index() {
  return (
    <>
      <div>
        <h3>Index</h3>
      </div>
    </>
  );
}

export default Index;
